@import '../core_base.scss';

.dropzone {
  @media (min-width: 992px) {
    min-width: 300;
    height: 285px;
    border: 3px solid $sail-light-grey-background;
    border-radius: 5px;
    position: relative;
    margin-bottom: 50px;
    transition: 0.3s ease;
  }
}

.uploading {
  margin: 16px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: $lumi-purple;
    font-family: $lumi-body-medium-font;
  }
}

.uploadWrapper {
  text-align: center;
  width: 100%;
  .filesPreview {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 16px;
    span {
      margin: 8px 0;
      font-size: 24px;
      color: $lumi-purple;
      font-family: $lumi-body-medium-font;
    }
    .filesPreviewTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .clearButton {
        padding-left: 8px;
        font-size: 20px;
        font-family: $lumi-body-medium-font;
        color: $lumi-error-400;
        &:hover {
          color: $lumi-error-300;
        }
      }
    }
    .file {
      margin: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 20px;
      }
    }
  }
}

.dropzoneActive {
  border-color: $sail-turqoise;
  i {
    color: $sail-turqoise !important;
  }
}

.dropzoneReject {
  border-color: $sail-red;

  &:after {
    content: 'Invalid File';
    background-color: rgba($sail-red, 0.5);
    color: white;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.or {
  display: none;
  @media (min-width: 992px) {
    display: inline;
  }
}

.body {
  padding: 60px;
  padding-bottom: 40px;
  text-align: center;
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
  .hintIcon {
    margin-bottom: 30px;
    svg {
      width: 70px;
      height: 70px;
    }
    i {
      transition: 0.3s ease;
      font-size: 60px;
      color: $lumi-typography;
    }
  }
}
.info {
  font-size: 16px;
  font-weight: 600;
  color: $lumi-typography;
  margin-bottom: 10px;
}

.progressWrapper {
  padding: 30px 50px;
  .info {
    margin-top: 20px;
  }
}

.selectFile {
  width: 75% !important;
  margin-top: 50px;
  @media (min-width: 992px) {
    margin-top: 0;
    position: absolute !important;
    bottom: -30px;
    top: initial !important;
    left: 13%;
  }
}

.selectMore {
  background: $lumi-typography !important;
  width: 75% !important;
  margin-top: 50px;
  @media (min-width: 992px) {
    margin-top: 0;
    position: absolute !important;
    top: -27px !important;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.selectMoreWrapper {
  // display: none;
  button {
    background: $lumi-typography !important;
  }
  @media (min-width: 992px) {
    display: block;
    position: relative;
    width: 100%;
    margin-top: 64px;
  }
}

.imagePreview {
  width: 300px;
  height: 125px;
  margin: 0 auto;
  margin-top: 35px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.largeImagePreview {
  width: 400px;
  height: 200px;
}

.remove {
  color: $sail-red;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;
  cursor: pointer;
  i {
    margin-right: 7px;
  }
  &:hover {
    color: darken($sail-red, 10%);
  }
}
