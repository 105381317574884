@import '../../../common/sass/core_base.scss';

@font-face {
  font-family: 'lumi-icon';
  src: url('~/../common/sass/icons/fonts/sail-icon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.wrapper {
  position: relative;
}

.rightActions {
  position: absolute;
  right: 10px;
  top: 13px;

  a {
    color: $lumi-typography;
  }
}

.group {
  position: relative;
  margin-top: 0;
  margin-bottom: 3em;

  .children {
    margin-top: 10px;
  }
  &.hasIcon {
    margin-top: 0;
    input {
      padding-left: 30px;
    }
  }
}

.icon {
  position: absolute;
  top: 13px;
  width: 20px;
  height: 20px;
  user-select: none;
  pointer-events: none;
  svg {
    width: 20px;
    height: 20px;
  }
}

.bar {
  position: relative;
  display: block;
  &:before,
  &:after {
    content: '';
    height: 4px;
    width: 0;
    bottom: 1px;
    position: absolute;
    transition: 0.2s ease all;
    background: $sail-turqoise;
    border: 1px;
    border-radius: 4px;
  }

  &:before {
    border-radius: 0px 4px 4px 0px;
    left: 50%;
  }
  &:after {
    border-radius: 4px 0px 0px 4px;
    right: 50%;
  }
}

.error .bar {
  &:before,
  &:after {
    background-color: $sail-error;
  }
}

.barInactive {
  @extend .bar;
  &:before,
  &:after {
    width: 50%;
    background: $sail-placeholder-text;
  }
}

.initial-label {
  font-size: $input-font-size;
  top: 0.625em;
}
.activated-label {
  top: -20px;
  font-size: 14px;
}

.input,
.input input {
  font-size: $input-font-size;
  font-family: $lumi-heading-font;
  padding: 0.5em 0.5em 0.7em 0em;
  display: block;
  width: 100%;
  color: $sail-input;
  border: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none !important;
    border: 0;
  }
  &:disabled {
    color: $sail-middle-grey;
    user-select: none;
    cursor: not-allowed;
  }
  &:focus,
  &.active {
    & ~ .label {
      @extend .activated-label;
    }
  }
}

.input input {
  padding: 0;
}

.focus .label {
  @extend .activated-label;
}

select.input {
  cursor: pointer;
  outline: none;
  &:focus {
    outline: none;
  }
  &:active {
    & ~ .dropdown {
      transform: rotate(180deg);
    }
  }
  &:not(.active) {
    & ~ .label {
      @extend .initial-label;
    }
  }
}

.dropdown {
  position: absolute;
  right: 5px;
  top: 15px;
  color: $sail-border-color;
  font-size: 12px;
  transition: transform 0.2s ease;
  transform: rotate(0deg);
}

.actionText {
  font-size: 14px;
  color: $sail-grey;
  user-select: none;
  font-family: $lumi-heading-font;
  opacity: 0.7;
  &.error {
    color: $sail-red;
  }
  &.warning {
    color: $sail-amber;
  }
  &.success {
    color: $sail-green;
  }
}

.errorLabel {
  text-align: left;
  color: $sail-error;
  font-weight: 600;
  margin-top: 10px;
  display: block;
  font-size: 14px;
  margin-bottom: 25px;
  i {
    margin-right: 10px;
  }
}

.left {
  text-align: left;
}

.input:focus,
.focus,
.input.active {
  & ~ .bar,
  .bar {
    &:before,
    &:after {
      width: 50%;
    }
  }
}

.label {
  color: $sail-placeholder-text;
  font-family: $lumi-heading-font;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0em;
  transition: 0.2s ease all;
  @extend .initial-label;
}

.checkbox {
  label {
    @extend p;
    position: relative;
    padding-left: 50px;
    text-align: left;
    cursor: pointer;
    display: flex;
    min-height: 25px;
    align-items: center;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 25px;
      height: 25px;
      left: 0;
      text-align: center;
      line-height: 25px;
      border: 1px solid $sail-border-grey;
      border-radius: 3px;
      align-self: flex-start;
    }

    &:after {
      font-family: 'lumi-icon' !important;
      content: '\e93c';
      color: $lumi-yellow;
      display: inline-block;
      position: absolute;
      left: -1px;
      top: 7px;
      font-size: 13px;
      width: 30px;
      height: 10px;
      text-align: center;
      transform: scale(0, 0);
      transform-origin: center;
      opacity: 0;
      transition: all 0.1s;
    }
  }

  input {
    display: none;
    &:checked + label:after {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
}

.radio {
  display: flex;
  margin-top: 16px;

  label {
    @extend p;
    position: relative;
    padding-left: 50px;
    text-align: left;
    cursor: pointer;
    display: flex;
    min-height: 25px;
    margin-bottom: 32px;
    align-items: center;
    margin-right: 64px;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 25px;
      height: 25px;
      left: 0;
      text-align: center;
      line-height: 25px;
      border: 1px solid $sail-border-grey;
      border-radius: 32px;
      align-self: flex-start;
    }

    &:after {
      font-family: sans-serif !important;
      content: '\2022';
      color: $lumi-yellow;
      display: flex;
      position: absolute;
      font-size: 40px;
      left: 0;
      top: 0;
      width: 27px;
      height: 27px;
      align-items: center;
      justify-content: center;
      opacity: 0;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    outline: 0;
    pointer-events: none;
    &:checked + label:after {
      opacity: 1;
    }
    &:focus + label:before {
      border: 1px solid $lumi-pink;
    }
  }
}
