@import '../variables';
@import '../layout/layout';

.modalWrapper {
  @extend .modalWrapper;
  background-color: rgba(93, 114, 135, 0.5);
  position: fixed;
}

.modalContent {
  @extend .smallModalWrapper;
  top: 5%;
  height: 90%;
  width: 75%;

  .modalContainer {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0 25px;
  }
}
