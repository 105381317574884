@import '../../../common/sass/variables';
@import '../../../common/sass/layout/flex';

.breadcrumbs {
  display: inline-block;
  position: relative;
  cursor: pointer;

  @media (max-width: 1399px) {
    .crumb,
    .firstCrumb,
    .lastCrumb {
      display: none;
    }

    .activeCrumb {
      display: flex !important;
    }

    .next {
      display: flex !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100px;
    }

    .previous {
      display: flex !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 100px;
    }
  }

  .next,
  .previous {
    display: none;
    @extend .flexCenter;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    &:hover {
      background: $lumi-grey-200;
    }
    &.disabled {
      svg {
        fill: $lumi-grey-300 !important;
      }
    }
    svg {
      width: 15px;
      fill: $lumi-pink-600;
    }
  }

  .activeCrumb {
    background: $lumi-typography !important;
    color: #fff;
  }

  .crumbs {
    display: inline-block;
    cursor: pointer;
    padding-left: 50px;

    @media (max-width: 1399px) {
      padding: 0;
      margin: 0 -48px;
    }
  }

  .crumb {
    padding: 8px 24px;
    float: right;
    border-radius: 50px;
    margin-left: -50px;
    background: $lumi-grey-200;
    border-right: 1px $lumi-grey-300 solid;

    @media (max-width: 1399px) {
      padding: 8px 24px !important;
      margin: 0 -24px !important;
    }

    &:nth-child(2) {
      padding-right: 64px;
      @media (max-width: 1399px) {
        padding-right: 0;
      }
    }

    &:not(:last-child) {
      padding-left: 64px;
    }
    &:last-child {
      padding-left: 48px;
    }
    &:first-child {
      padding-right: 48px;
    }
    &:hover {
      background: $lumi-grey-300;
    }

    .timeRemaining {
      color: $lumi-pink;
      padding-left: 2px;
      @media (min-width: 320px) and (max-width: 1399px) {
        display: none;
      }
    }
  }

  .indicatorCrumb {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .crumbEnd {
    @extend .flexCenter;
    border: 1px $lumi-grey-300 solid;
    border-width: 0.5px;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-53%);
    background: $lumi-grey-200;
    border-radius: 25px;
    font-size: 26px;
    @media (max-width: 1399px) {
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }

  .firstCrumb {
    @extend .crumbEnd;
    left: -14px;
  }

  .lastCrumb {
    @extend .crumbEnd;
    right: -14px;
  }
}
