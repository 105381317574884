@import '../../../common/sass/core_base';

.container {
  display: flex;
  .icon {
    margin-right: 15px;
  }
  .inner > b {
    font-size: 13px;
  }
  h4 {
    margin: 0;
  }
}

.fixed {
  @media (min-width: 992px) {
    display: flex;
  }
  background-color: $sail-light-grey;
  display: none;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 60vh;
}
