/**
 * @license
 * MyFonts Webfont Build ID 3746797, 2019-04-13T01:25:23-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirNextLTPro-Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-bold/
 * 
 * Webfont: AvenirNextLTPro-Demi by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi/
 * 
 * Webfont: AvenirNextLTPro-Heavy by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-heavy/
 * 
 * Webfont: AvenirNextLTPro-It by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-italic/
 * 
 * Webfont: AvenirNextLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-medium/
 * 
 * Webfont: AvenirNextLTPro-Regular by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-regular/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3746797
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
/* TODO this may be causing issues */
/* @import url('https://hello.myfonts.net/count/3b6970'); */

@font-face {
  font-family: 'AvenirNext';
  font-weight: bold;
  font-style: normal;
  src: url('/./../common/webfonts/392BED_0_0.woff') format('woff');
}

@font-face {
  font-family: 'AvenirNextLTPro-Demi';
  src: url('/./../common/webfonts/392BED_1_0.woff') format('woff');
}

@font-face {
  font-family: 'AvenirNextLTPro-Heavy';
  src: url('/./../common/webfonts/392BED_2_0.woff') format('woff');
}

@font-face {
  font-family: 'AvenirNext';
  font-weight: normal;
  font-style: italic;
  src: url('/./../common/webfonts/392BED_3_0.woff') format('woff');
}

@font-face {
  font-family: 'AvenirNextLTPro-Medium';
  src: url('/./../common/webfonts/392BED_4_0.woff') format('woff');
}

@font-face {
  font-family: 'AvenirNext';
  font-weight: normal;
  font-style: normal;
  src: url('/./../common/webfonts/392BED_5_0.woff') format('woff');
}
