.bankfeedsContainer {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-overflow-scrolling: touch;
  height: 1000px;
  position: relative;
  max-height: 70vh;

  @media (min-width: 768px) and (orientation: landscape) {
    height: 670px;
    max-height: none;
  }
}

.landingWrapper {
  margin-top: 30px;
}
