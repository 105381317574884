@import '../core_base.scss';

@font-face {
  font-family: 'lumi-icon';
  src: url('../icons/fonts/sail-icon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.wrapper {
  position: relative;
}

.rightActions {
  position: absolute;
  right: 0;
  top: 17px;
}

.group {
  position: relative;
  margin-top: 0;
  margin-bottom: 3em;

  .children {
    margin-top: 10px;
  }
  &.hasIcon {
    margin-top: 0;
    input {
      padding-left: 30px;
    }
  }
}

.icon {
  position: absolute;
  top: 13px;
  width: 20px;
  height: 20px;
  user-select: none;
  pointer-events: none;
  svg {
    width: 20px;
    height: 20px;
  }
}

.bar {
  position: relative;
  display: block;
  &:before,
  &:after {
    content: '';
    height: 4px;
    width: 0;
    bottom: 1px;
    position: absolute;
    transition: 0.2s ease all;
    background: $sail-turqoise;
    border: 1px;
    border-radius: 4px;
  }

  &:before {
    border-radius: 0px 4px 4px 0px;
    left: 50%;
  }
  &:after {
    border-radius: 4px 0px 0px 4px;
    right: 50%;
  }
}

.error .bar {
  &:before,
  &:after {
    background-color: $sail-error;
  }
}

.barInactive {
  @extend .bar;
  &:before,
  &:after {
    width: 50%;
    background: $sail-placeholder-text;
  }
}

.initial-label {
  font-size: $input-font-size;
  top: 0.625em;
  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
}
.activated-label {
  top: -28px;
  color: $lumi-typography;
  font-family: $lumi-subheading-font;
  left: 0em;
  font-size: 14px;
}

.activeLabel {
  position: absolute;
  text-align: left;
  top: -24px;
  color: $lumi-typography;
  font-family: $lumi-subheading-font;
  left: 0em;
  font-size: 14px;
  line-height: 1.2;
}

.activeLabelV2 {
  /*position: absolute;*/
  text-align: left;
  top: -24px;
  color: $lumi-typography;
  font-family: $lumi-avenir-next-font;
  left: 0em;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

textarea.input {
  min-height: 150px;
  resize: none;
}

.input,
.input input,
.overwritableField {
  font-size: $input-font-size;
  font-family: $lumi-heading-font;
  padding: 10px 10px 10px 15px;
  display: block;
  width: 100%;
  color: $lumi-typography;
  background: #fff;
  border: solid $lumi-border-color 1px;
  border-radius: 10px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
}

.input,
.input input {
  &:focus {
    outline: none !important;
  }
  &:disabled {
    color: $lumi-typography;
    user-select: none;
    background-color: $lumi-background;
    border: none;
    cursor: not-allowed;
  }
  &:focus,
  &.active {
    & ~ .label {
      @extend .activated-label;
    }
  }

  &:focus {
    border-color: $lumi-pink-400;
  }

  &::placeholder {
    color: $lumi-grey-500;
  }

  &.small {
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
    }
  }

  &.error {
    border-color: $lumi-error-400;
  }
}

.input input {
  padding: 0;
}

.focus .label {
  @extend .activated-label;
}

select.input {
  cursor: pointer;
  outline: none;
  &:focus {
    outline: none;
  }
  &:active {
    & ~ .dropdown {
      transform: rotate(180deg);
    }
  }
  &:not(.active) {
    & ~ .label {
      @extend .initial-label;
    }
  }
}

.dropdown {
  position: absolute;
  right: 15px;
  top: 15px;
  color: $lumi-purple;
  font-size: 12px;
  transition: transform 0.2s ease;
  transform: rotate(0deg);

  svg {
    height: 1.5em;
    fill: $lumi-purple;
  }
}

.actionText {
  font-size: 14px;
  color: $sail-grey;
  user-select: none;
  font-family: $lumi-heading-font;
  opacity: 0.7;
  &.error {
    color: $sail-red;
  }
  &.warning {
    color: $sail-amber;
  }
  &.success {
    color: $sail-green;
  }
}

.errorText {
  text-align: left;
  color: $sail-error;
  font-weight: 400;
  margin-top: 2px;
  display: block;
  font-size: 12px;
  margin-bottom: 25px;
  i {
    margin-right: 10px;
  }
}

.errorLabel {
  text-align: left;
  color: $sail-error;
  font-weight: 600;
  margin-top: 10px;
  display: block;
  font-size: 14px;
  margin-bottom: 25px;
  i {
    margin-right: 10px;
  }
}

.input:focus,
.focus,
.input.active {
  & ~ .bar,
  .bar {
    &:before,
    &:after {
      width: 50%;
    }
  }
}

.label {
  color: $lumi-primary-grey;
  font-family: $lumi-heading-font;
  line-height: 1.2;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.5em;
  transition: 0.2s ease all;
  &.small {
    font-size: 16px;
  }
  @extend .initial-label;
}

.labelV2 {
  color: $lumi-primary-grey;
  font-family: $lumi-avenir-next-font;
  line-height: 22px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  left: 0.5em;
  transition: 0.2s ease all;
  letter-spacing: 0em;
  text-align: left;
  &.small {
    font-size: 16px;
  }
  @extend .initial-label;
}

.lumiFieldErrorLabel {
  @extend .activeLabel;
  min-width: 200px;
  margin: 0;
  line-height: 1.2;
  color: $lumi-error-400;
  height: 1em;
}

.lumiFieldErrorLabelV2 {
  @extend .activeLabelV2;
  min-width: 200px;
  margin: 0;
  color: $lumi-error-400;
  height: 1em;
  font-family: $lumi-avenir-next-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.checkbox {
  label {
    @extend p;
    position: relative;
    padding-left: 50px;
    text-align: left;
    cursor: pointer;
    display: block;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 25px;
      height: 25px;
      left: 0;
      text-align: center;
      line-height: 25px;
      border: 1px solid $sail-border-grey;
      border-radius: 3px;
    }

    &:after {
      font-family: 'sail-icon' !important;
      content: '\e93c';
      color: $sail-turqoise;
      display: inline-block;
      position: absolute;
      left: -1px;
      top: 7px;
      font-size: 13px;
      width: 30px;
      height: 10px;
      text-align: center;
      transform: scale(0, 0);
      transform-origin: center;
      opacity: 0;
      transition: all 0.1s;
    }
  }

  input {
    visibility: hidden;
    &:checked + label:after {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
}

.search {
  position: relative;
  max-width: 300px;
  .group {
    &.hasIcon {
      margin-top: 0;
      input {
        padding-left: 40px;
      }
    }
  }

  input {
    color: $lumi-typography;
    font-size: 20px;
    padding-bottom: 12px;
    font-weight: 300;
    padding-left: 50px;
  }

  input::placeholder {
    color: $lumi-grey-500;
  }

  .bar {
    &:before,
    &:after {
      background: $lumi-primary-grey;
      height: 2px;
    }
  }

  .icon {
    padding-left: 10px;
    color: $lumi-pink;
    font-size: 20px;
    top: 12px;
  }
}

.cancelButton {
  position: absolute;
  right: 7px;
  top: 16px;
  font-size: 16px;
  color: $sail-grey;
  opacity: 0.4;
  cursor: pointer;
  transition: 0.2s ease all;

  &:hover {
    opacity: 1;
  }
}

.selectWrapper {
  position: relative;
  :global {
    .Select-control {
      border-color: $lumi-secondary-grey;
      background-color: $lumi-background;
      font-size: 20px;

      .Select-value {
        line-height: 33px;
        background-color: white;
        border-color: $lumi-purple;
        color: $lumi-typography;

        .Select-value-label {
          position: absolute;
          transform: translateY(25%);
        }
      }
    }
    .Select-placeholder {
      line-height: 50px;
    }

    .Select-value-icon {
      border-color: $lumi-border-color;
    }
    .Select-clear-zone {
      padding-top: 5px;
      line-height: 50px;
    }
  }
}

.multiSelectWrapper {
  text-align: left;
  .multiSelect {
    @extend .selectWrapper;
  }
  position: relative;
  margin-bottom: 50px;
}

/* Can't refer to variables inside _select.scss so putting it here */
.reactSelectDisabled {
  color: $lumi-primary-grey;
}
.reactSelectEnabled {
  color: $lumi-typography;
}

.editableLabel {
  font-family: $lumi-subheading-font;
  font-size: 36px;
  padding: 0.2em 0.3em 0 0;
  line-height: 1.2em;
  color: $lumi-pink-700;
  border: 2px solid transparent;
  transition: all ease-in-out 0.2s;
  cursor: pointer;

  &:hover {
    background: $lumi-grey-100;
    padding-left: 0.2em;
  }

  &:focus {
    background: $lumi-grey-100;
    border-color: $lumi-purple;
    cursor: auto;
    padding-left: 0.2em;
  }
}

:global {
  .geosuggest {
    .geosuggest__input-wrapper {
      input {
        border: 0;
      }
    }
  }
}
