@import '../../../common/sass/variables';
@import '../../../common/sass/layout/flex';

.documentCapturePage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  margin-bottom: 32px;

  // Smart Phones
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    padding: 4px;
  }

  // Tablet / Landscape
  @media (min-width: 481px) and (max-width: 766px) {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }

  .documentCaptureBox {
    position: relative;
    min-width: 450px;
    min-height: 300px;
    width: 450px;
    // Smart Phones
    @media (min-width: 320px) and (max-width: 480px) {
      min-width: 98%;

      .nextButton {
        margin-bottom: 32px;
      }
    }

    &.center {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .innerBox {
      @extend .flexCenter;
      align-items: flex-start;
      flex-direction: column;
      padding: 16px 32px 32px 32px;
      box-shadow: $lumi-grey-200 0px 4px 4px;
      background: #fff;
      border-radius: 10px;

      h1 {
        text-align: left;
      }
      span {
        color: #3c5572;
        font-size: 20px;
      }
    }

    .nextButton {
      @extend .flexCenter;
      width: 100%;
      margin-top: 32px;
    }
  }
}

.documentCollectionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f8fafc;
  align-items: center;

  .stepProgress {
    display: none;
    width: 100%;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    display: inline-block;
  }

  @media (min-width: 320px) and (max-width: 1399px) {
    .stepProgress {
      display: block;
    }
  }

  .documentCollectionHeader {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 32px;
    width: 100%;

    // Just Smart Phones
    @media (min-width: 320px) and (max-width: 480px) {
      flex-direction: column;
    }

    // Smart Phones to Lanscape
    @media (min-width: 320px) and (max-width: 767px) {
      svg {
        height: 50px;
      }
    }

    .navigation {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      padding-bottom: 2rem;
      // Just Smart Phones
      @media (min-width: 320px) and (max-width: 480px) {
        position: relative;
        left: initial;
        margin: 32px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        transform: none;
      }
    }
  }
}
