@import '../../../../../common/sass/variables';

.thanksPage {
  svg {
    height: 225px;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 500px;

    h1 {
      font-size: 3em;
    }
    .copy {
      font-size: 20px;
      color: $lumi-typography;
      line-height: 1.5;
    }
  }
}
