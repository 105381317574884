@import '../../../../../common/sass/variables';

.whatWeNeed {
  margin-top: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.requirementBullet {
  margin-top: 24px;
  position: relative;
  font-size: 18px;
  padding-left: 24px;
  color: $lumi-purple;
  font-family: $lumi-body-medium-font;

  &::before {
    position: absolute;
    left: 0;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: $lumi-pink;
    border-radius: 20px;
  }
}
