@import '../core_base';

.mp-350 {
  margin-top: 350px;
}

.mp-150 {
  margin-top: 150px;
}

.mp-50 {
  margin-top: 50px;
}

.formTitle {
  margin-bottom: 0.5em;
  text-transform: capitalize;
}

.formTopText {
  color: $lumi-grey-500;
  margin-bottom: 3em;
}

.container {
  max-width: $form-width;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding-top: 0;
  }
}

.formField {
  position: relative;
  margin: 25px;
}

.field {
  margin: 40px 0;
}

.subFormField {
  @extend .formField;
  z-index: 3;
}

.helpText {
  color: $lumi-grey-500;
}
