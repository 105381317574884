@import '../../../../../common/sass/layout/flex';
@import '../../../../../common/sass/variables';

.identityFormField {
  margin-top: 48px;
  min-width: 350px;

  // Smart Phones
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    min-width: 200px;
  }
}

.nextButton {
  @extend .flexCenter;
  width: 100%;
  margin: 24px 0;

  // Smart Phones
  @media (min-width: 320px) and (max-width: 766px) {
    margin-bottom: 0;
  }
}

.searchIcon {
  padding-right: 8px;
  color: $lumi-pink-700;
}

.abnLoader {
  position: absolute;
  right: 1px;
  top: 4px;
}

.abrLink {
  position: absolute;
  bottom: 44px;
  right: -8px;
  width: 300px;
  text-align: right;
  a {
    font-size: 14px;
    color: $lumi-purple;
    font-family: $lumi-subheading-font;
  }
}

.abnCheckLabel {
  color: $lumi-pink-700;
  // Smart Phones
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
}
