html,
body {
  font-family: $lumi-body-font;
  background: #fff;
  text-rendering: geometricPrecision;
  overflow-x: hidden;
  margin: 0;
  height: 100%;
}

.signup-container {
  overflow-y: auto;
  height: 100vh;
  @media (min-width: 320px) and (max-width: 768px) {
    height: auto;
  }
}

.signup-body {
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  bottom: 0;
  right: 0;
  position: relative;
  left: 0;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - #{$header-height});
  top: $header-height;
  display: block;
  overflow-y: auto;

  @media screen and (max-width: $xs-breakpoint-max-width) {
    top: 0;
  }
}

.signup-content {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 50%;
  @media screen and (max-width: $xs-breakpoint-max-width) {
    width: 95%;
  }
}

.auth-container .signup-content {
  width: 95%;
}

.bodyWrapper {
  background-color: $sail-purple;
}

.bodyContainer {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  width: 100vw;
}

.content {
  width: $body-max-width;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: $md-breakpoint-min-width) {
    margin-top: 40px;
  }
}

.bodyInner {
  margin-top: $header-height-mobile;
  padding-bottom: $header-height-mobile + 90px;
  @media screen and (min-width: $md-breakpoint-min-width) {
    margin-top: $header-height;
    padding-bottom: $header-height + 90px;
  }
  height: 100vh;
  @media screen and (min-width: $md-breakpoint-min-width) {
    padding-left: 10px;
    padding-right: 10px;
  }
  border: 1px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  @media screen and (min-width: $md-breakpoint-min-width) {
    background-color: #fff;
  }
}

.page-header {
  margin-bottom: 30px;
}

.max-width {
  max-width: 550px;
  margin: 0 auto;
}

.icon-current,
.icon-next {
  position: absolute;
  top: -20px;
  svg {
    width: 90px;
    height: 90px;
  }
  @media screen and (min-width: $sm-breakpoint-min-width) {
    svg {
      width: 150px;
      height: 150px;
    }
  }
  @media screen and (min-width: $md-breakpoint-min-width) {
    svg {
      width: 250px;
      height: 250px;
    }
  }
}

.icon-current {
  left: -30px;
  @media screen and (min-width: $sm-breakpoint-min-width) {
    left: -35px;
  }
  @media screen and (min-width: $md-breakpoint-min-width) {
    left: -50px;
  }
}

.icon-next {
  opacity: 0.2;
  right: -30px;
  @media screen and (min-width: $sm-breakpoint-min-width) {
    right: -80px;
  }
  @media screen and (min-width: $md-breakpoint-min-width) {
    right: -100px;
  }
}

hr {
  border: 0;
  height: 3px;
  background-color: $sail-light-grey;
}

@for $i from 0 through 12 {
  .mt-#{$i} {
    margin-top: 5px * $i;
  }

  .mr-#{$i} {
    margin-right: 5px * $i;
  }

  .mb-#{$i} {
    margin-bottom: 5px * $i;
  }

  .ml-#{$i} {
    margin-left: 5px * $i;
  }

  .m-#{$i} {
    margin: 5px * $i;
  }

  .pt-#{$i} {
    padding-top: 5px * $i;
  }

  .pr-#{$i} {
    padding-right: 5px * $i;
  }

  .pb-#{$i} {
    padding-bottom: 5px * $i;
  }

  .pl-#{$i} {
    padding-left: 5px * $i;
  }

  .p-#{$i} {
    padding: 5px * $i;
  }
}
