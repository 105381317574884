@import './spinner';
@import '../_variables.scss';

@mixin iconBGCHover($color) {
  .icon {
    svg {
      fill: $color;
    }
  }
  &:hover {
    .icon {
      svg {
        fill: white;
      }
    }
    background-color: $color;
  }
}

.button,
a.button,
#nav a.button {
  border: 1px;
  border-radius: 30px;
  line-height: 30px;
  font-weight: 600;

  background-color: $lumi-pink;
  font-size: 16px;
  padding: 15px 15px;
  width: 100%;
  color: white;
  transition: 0.2s all ease;

  &:not(:disabled):hover {
    background-color: rgba($lumi-pink, 0.8);
    text-decoration: none;
    box-shadow: 0px 5px 10px 0px rgba($sail-shadow-color, 0.3);
    cursor: pointer;
    color: white;
  }

  &.inline {
    padding: 10px 25px;
    font-size: 14px;
    white-space: nowrap;
  }

  &.turqoise {
    background-color: $sail-turqoise;
    &:hover {
      background-color: $sail-turqoise-hover;
    }
  }

  &.upload {
    padding: 15px 25px;
    padding-right: 14px;
    @media (max-width: 768px) {
      margin-top: 25px;
      display: block;
    }
    i {
      color: #fff;
      margin-left: 15px;
      font-size: 18px;
      position: relative;
      top: 2px;
    }
    input {
      display: none;
    }
  }

  &:disabled {
    background-color: $lumi-light-pink;
  }

  &:focus {
    outline: none;
  }

  &.cta {
    background-color: $lumi-pink;
    font-size: 18px;
    padding: 15px 50px;
    font-weight: 500;
    border-radius: 50px;
    position: relative;
    top: 0px;
    color: white;
    display: inline-block;

    &:hover {
      top: -3px;
      color: white;
    }

    &.small {
      font-size: 18px;
      padding: 18px 35px;
    }
  }

  &.loading {
    &:after {
      content: '';
      @extend .spinner;
    }
  }
}

.statementsButtons {
  .upload {
    background-color: $lumi-purple;
  }

  .middle {
    float: none;
    font-size: 16px;
  }
}

.small {
  padding: 5px 10px !important;
  width: auto !important;
  height: auto !important;
}

.lumiButton {
  height: 2.5rem;
  padding: 0.5rem 2rem;
  margin: 0 4px;
  font-family: $lumi-avenir-next-font;
  border: 1px;
  border-radius: 5px;
  font-weight: 500;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  align-items: center;
  text-align: center;
  background-color: $lumi-pink-500;
  float: left;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.1s linear;
  box-shadow: 0px 4px 15px $lumi-grey-400;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: $lumi-hover-pink;
  }
  &:hover > .animatedIcon {
    transform: translate3d(0, -17px, 0);
  }
  &:active {
    box-shadow: 0px 2px 4px $lumi-grey-400;
    background: $lumi-pink-600;
  }

  &:focus {
    outline: none;
  }

  &.noAnimate > span {
    transform: none;
  }

  .animatedLabel {
    box-sizing: border-box;
  }

  &.secondary {
    background: $lumi-pink-100;
    border: 1px solid $lumi-pink-300;
    box-shadow: none;
    span {
      color: $lumi-pink-700;
    }
    &:hover {
      border: 1px solid $lumi-pink-700;
    }
  }

  &.refreshButton {
    border: 1px solid $lumi-typography;
    background-color: #fff;
    color: $lumi-typography;
    box-shadow: none;
    span {
      color: inherit !important;
    }
    &:hover {
      background-color: $lumi-typography;
      color: #fff;
    }
  }

  &.saveButton {
    background-color: $lumi-purple;
    &:hover {
      background-color: $lumi-hover-purple;
    }
  }

  &.cancelButton {
    background: #fff;
    box-shadow: none;
    border: 1px solid $lumi-grey-400;
    box-sizing: border-box;
    span {
      color: $lumi-grey-700;
      transform: none;
    }
    &:active {
      @extend .shadowDarkBlur4;
    }
    &:hover {
      span {
        color: $sail-red;
      }
      border-color: $sail-red;
    }
  }

  &.rounded {
    border-radius: 10px;
    // span {
    //     font-size: 14px !important;
    // }
  }

  &.destructive {
    background: $lumi-error-300;
    box-shadow: 0px 4px 15px $lumi-grey-400;

    &:active {
      box-shadow: none;
    }
  }
}

// .lumiButton > span {
//     color: $lumi-pink-100 !important;
//     font-size: 16px !important;
// 	display: inline-block;
// 	-webkit-transform: translate3d(-10px, 0, 0);
// 	transform: translate3d(-10px, 0, 0);
// 	-webkit-transition: -webkit-transform 0.3s;
// 	transition: transform 0.3s;
// 	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
// 	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
// }

.lumiButton .animatedIcon {
  position: absolute;
  left: 18px;
  font-size: 18px;
  -webkit-transform: translate3d(-40px, 2.5em, 0);
  transform: translate3d(-40px, 2.5em, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);

  svg {
    fill: #fff;
    g {
      fill: #fff;
    }
  }
}

.disabledLumiButton {
  @extend .lumiButton;
  background-color: $lumi-grey-200 !important;
  box-shadow: none;
  border-width: 0px !important;
  span {
    color: $lumi-grey-500 !important;
  }
  // filter: brightness(200%);
  cursor: not-allowed;
  &:hover {
    background: $lumi-grey-400;
  }
}

// .cancelButton {
//     padding: 0.4em 30px 0.4em 50px;
//     font-family: $lumi-subheading-font;
//     outline: 0;
//     border: none;
//     cursor: pointer;
//     font-size: 16px;
//     color: $lumi-typography;
//     align-items: center;
//     background: none;
//     float: left;
//     display: block;
//     position: relative;
//     z-index: 1;
//     overflow: hidden;

//     & > span {
//         color: $lumi-typography;
//     }
// }

.half {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  a {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
  }
  .cta,
  button {
    width: initial;
    padding-left: 30px;
    padding-right: 30px;
    flex: 1;
  }
}

.nofill {
  .icon {
    svg {
      fill: transparent !important;
    }
  }
}

.phatButton {
  padding: 0.6em 30px 0.6em 50px;
}

.smolButton {
  font-size: 14px;
}

.lumiIconButton {
  background: none;
  border-radius: 5px;
  outline: 0;
  border: none;
  padding: 0;
  display: block;

  .icon {
    svg {
      width: 34px;
      // fill: $lumi-pink;
    }
  }

  &:hover {
    .icon {
      svg {
        fill: $lumi-pink-200;
      }
    }
  }

  &.isBorder {
    border-radius: 10px;
    border: 1px solid $lumi-grey-400;
    margin: auto;
    padding: 14px;
    box-sizing: border-box;
  }

  &.noMargin {
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  &.addIconButton {
    @include iconBGCHover($lumi-typography);
  }

  &.cancelIconButton {
    @include iconBGCHover($sail-red);
  }
}

.caret span::after {
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: 0.15em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
