@import '../../../../../common/sass/layout/flex';
@import '../../../../../common/sass/variables';

.paddedSpan {
  padding-top: 16px;
}

.aboutFormField {
  margin-top: 24px;
  min-width: 350px;
  textarea {
    height: 150px;
  }

  width: 100%;

  // Smart Phones
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    min-width: 200px;
  }
}

.requirementBullet {
  margin-top: 16px;
  position: relative;
  font-size: 18px;
  padding-left: 24px;
  color: $lumi-purple;
  font-family: $lumi-body-medium-font;

  &::before {
    position: absolute;
    left: 0;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: $lumi-pink;
    border-radius: 20px;
  }
}

.nextButton {
  @extend .flexCenter;
  width: 100%;
  margin: 24px 0;

  // Smart Phones
  @media (min-width: 320px) and (max-width: 766px) {
    margin-bottom: 0;
  }
}
