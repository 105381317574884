@import '../variables';

:global {
  .react-select__menu {
    z-index: 999 !important;
  }
  .react-select__control {
    padding: 3px 0px !important;
    position: relative;
    border-radius: 10px !important;
    border-color: $lumi-border-color !important;
    @media screen and (min-width: 1024px) {
      font-size: 16px;
      .react-select__value-container {
        padding: 0 16px;
        .react-select__single-value {
          font-size: 16px;
        }
        .react-select__placeholder {
          font-size: 16px;
          color: $lumi-grey-500;
        }
      }
    }
    .react-select__indicator {
      padding: 12px;
    }
  }
  .react-select__control--is-disabled {
    background-color: $lumi-background !important;
    border-width: 0px !important;
  }
}
