@import '../../../../../common/sass/_variables.scss';

.productSelector {
  :global {
    .lumi-radio-group-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .lumi-radio-button {
      height: 40px;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 1rem;
      position: relative;
      line-height: 20px;
      margin-right: 0.5rem;
      margin-bottom: 1.5rem;
      input {
        & + label {
          font-family: AvenirNextLTPro-Demi;
          color: #00234b;
          white-space: nowrap;
          padding-left: 20px;
          &:before {
            --tw-shadow: 0px 2px 2px rgba(26, 32, 44, 0.12),
              0px 1px 3px rgba(26, 32, 44, 0.01);
            --tw-shadow-colored: 0px 2px 2px var(--tw-shadow-color),
              0px 1px 3px var(--tw-shadow-color);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
              var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

            left: 14px;
            width: 20px;
            height: 20px;
            color: #e2e8f0;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            border-radius: 99px;
            color: #e2e8f0;
          }
        }
        &:checked + label {
          color: #fd55d8;
          &:after {
            border-color: #fd55d8;
          }
          &:before {
            background-color: #fd55d8;
            border-color: #fd55d8;
            box-shadow: inset 0px 0px 0px 3px #fff;
          }
        }
      }
    }
  }
}
