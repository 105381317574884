@import '../variables';
@import '../layout/flex';

.lumiCheckboxContainer {
  display: flex;

  .lumiCheckbox {
    @extend .flexCenter;
    background: #fff;
    height: 40px;
    width: 40px;
    border: 1px $lumi-secondary-grey solid;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 1rem;

    &:hover {
      background: $lumi-grey-100;
    }

    input {
      display: none;
    }

    label {
      @extend .flexCenter;
      cursor: pointer;
      .tick {
        @extend .flexCenter;
        height: 40px;
        width: 40px;

        svg {
          width: 25px;
        }
      }
    }

    &.disabled {
      background: $lumi-grey-300;
      &:hover {
        background: $lumi-grey-300;
      }
    }

    .disabled {
      background: $lumi-grey-300;
      &:hover {
        background: $lumi-grey-300;
      }
    }
  }
  .copy {
    line-height: 40px;
  }
}
