@import '../../../common/sass/core_base.scss';

.fileList {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;

  .image {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 70px;
    width: 125px;
    margin-right: 20px;
  }

  .name {
    flex-grow: 1;
    font-size: 16px;
    color: $sail-text-light-grey;
    text-align: left;
    font-weight: 600;
    word-wrap: break-word;
    word-break: break-all;
  }

  .remove {
    color: $sail-text-light-grey;
    &:hover {
      color: darken($sail-text-light-grey, 20%);
    }
  }
}

.body {
  max-width: 500px;
  margin: 0 auto;
}

.fileUploadWrapper {
  margin-top: 50px;
  @media (min-width: 992px) {
    margin: 0 auto;
    margin-bottom: 100px;
    margin-top: 100px;
  }
}
