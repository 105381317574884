@import '../variables';

.progress {
  height: 8px;
  background-color: #e4e4ea;
  border: 1px;
  border-radius: 2px;
  .bar {
    position: relative;
    background-color: $lumi-yellow;
    height: 8px;
    &.accent {
      background-color: $lumi-purple;
    }
  }
  &.thin {
    height: 5px;
    .bar {
      height: 5px;
    }
  }
}
