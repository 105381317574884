@import '../variables';

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes drop-in {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 10%;
    opacity: 1;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  display: none;
  &.open {
    display: block;
    .box {
      animation: drop-in 0.2s ease;
      animation-fill-mode: forwards;
    }
    .overlay {
      animation: fade-in 0.2s ease;
      animation-fill-mode: forwards;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -1;
    opacity: 0;
  }
  .box {
    position: absolute;
    max-height: 100vh;
    overflow: auto;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.02);
    border: 1px;
    z-index: 1;
    max-width: 850px;
    width: 90%;
  }
  .header {
    height: 70px;
    background-color: darken($sail-turqoise, 30%);
    font-family: $lumi-heading-font;
    font-size: 20px;
    border-radius: 10px 10px 0px 0px;
    border: 1px;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
    .close {
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        color: darken(white, 20%);
      }
    }
    .title {
      flex-grow: 1;
    }

    &.error {
      background-color: darken($sail-red, 5%);
    }
  }
  .body {
    padding: 50px;
    p {
      margin-bottom: 0;
      font-size: 18px;
    }
  }
  hr {
    margin-bottom: 0;
    margin-top: 0;
  }
  .actions {
    text-align: right;
    padding: 20px;
  }
}
