a {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  color: $lumi-purple;
  transition: 0.3s ease;
  &:hover {
    color: $lumi-typography;
    text-decoration: none;
  }
  &.accent {
    text-decoration: none;
    color: $lumi-typography;
  }
}

.text-center {
  text-align: center;
}

.md-icon {
  font-size: 16px;
}

.lg-icon {
  font-size: 25px;
}

.clickable {
  cursor: pointer;
}

.accent {
  color: $sail-burnt-yellow;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $lumi-heading-font;
  font-weight: 800;
}

h1 {
  font-size: 36px;
  &.title {
    font-weight: 800;
    margin-bottom: 70px;
    font-size: 40px;
    @media (min-width: 768px) {
      font-size: 69px;
    }
    &.mb-0 {
      margin-bottom: 0;
    }
    &.sm-padded {
      padding-left: 45px;
      padding-right: 45px;
      @media (min-width: 768px) {
        padding: 0;
      }
    }
  }
}

h2 {
  font-size: 25px;
  @media (min-width: 768px) {
    font-size: 32px;
  }
}

h3 {
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
}

h1,
h2 {
  color: $lumi-typography;
}

h2,
h3 {
  color: $lumi-typography;
}

h4,
h5 {
  color: $lumi-typography;
}

h4 {
  font-size: 20px;
}

p {
  color: $lumi-typography;
  font-size: 16px;
  margin-bottom: 40px;
  font-weight: 400;
}

.textRight {
  text-align: right;
}

.turqoise {
  color: $sail-turqoise;
}

.error-text {
  text-align: left;
  color: $sail-error;
  font-weight: 600;
  margin-top: 5px;
  font-size: 14px;
  i {
    margin-right: 10px;
  }
}
