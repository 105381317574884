@import '../variables';
$number-size: 40px;
$small-number: 30px;

.numberWrapper {
  margin-right: 20px;
  position: relative;
  width: $number-size;
  height: $number-size;
  &:before {
    width: 28px;
    height: 28px;
    background-color: $sail-purple-shadow;
    content: '';
    display: block;
    border: 4px;
    top: 20px;
    left: 2px;
    border-radius: 32px 68px 30px 68px;
    position: absolute;
    transform: rotate(-17deg);
  }
}

.number {
  border: 3px solid white;
  border-radius: 35px;
  border-top-left-radius: 40px;
  text-align: center;
  width: $number-size;
  height: $number-size;
  color: white;
  background-color: $lumi-pink;
  font-weight: 600;
  position: absolute;
  font-size: 20px;
  line-height: 35px;
  top: 0;
  left: 0;
  i {
    line-height: 31px;
  }
}
.label {
  font-weight: 600;
  font-size: 16px;
  color: $lumi-typography;
}

.list {
  .label {
    color: $lumi-typography;
  }

  .numberWrapper {
    opacity: 0.5;
    &:before {
      background-color: $lumi-secondary-grey;
      width: 20px;
      height: 20px;
      top: 16px;
      left: 2px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .numberWrapper,
  .number {
    font-size: 16px;
    width: $small-number;
    height: $small-number;
  }

  .numberWrapper.completed {
    opacity: 1;
    span {
      display: none;
    }
    i {
      display: inline-block;
    }
    .number {
      color: white;
      background-color: $lumi-purple;
      i {
        font-size: 10px;
        line-height: 24px;
      }
    }
  }
  .numberWrapper.active {
    opacity: 1;
    .number {
      background-color: $lumi-purple;
    }
  }

  .number {
    border-color: white;
    background-color: $lumi-purple;
    line-height: 25px;
    span {
      color: white;
    }
    i {
      display: none;
    }
  }

  .numberNoBorder {
    @extend .number;
    border-color: $lumi-typography;
  }

  &.line {
    display: flex;
    max-width: 280px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .item {
      flex: 1;
      display: flex;
      &:last-child {
        flex: 0;
        display: block;
      }
      .line {
        text-align: center;
        flex-grow: 1;
        svg {
          width: 45px;
          height: 20px;
        }
      }

      .numberWrapper {
        margin-right: 0;
      }
    }
  }
}
