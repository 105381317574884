@import '_variables';
@import 'layout/_layout';

:global {
  @import 'utils/_typography';
  @import 'layout/_layout';
}

* {
  box-sizing: border-box;
}
