@import 'font_loader';

$xs-breakpoint-max-width: 768px;
$md-breakpoint-min-width: 992px;
$xl-breakpoint-min-width: 1500px;
$mobile-breakpoint-max-width: 480px;

$img-path: '/public/imgs/common';
$font-path: '/public/fonts';

// Font
$sail-heading-font: filson-soft, Lato, san-serif;
$sail-body-font: stevie-sans, Lato, sans-serif;

$lumi-heading-font: AvenirNext, san-serif;
$lumi-body-font: AvenirNext, san-serif;
$lumi-body-medium-font: AvenirNextLTPro-Medium, san-serif;
$lumi-subheading-font: AvenirNextLTPro-Demi, san-serif;
$lumi-italic-font: AvenirNext, san-serif;
$lumi-avenir-next-font: AvenirNext;

$body-max-width: 1440px;
$input-font-size: 20px;

$header-height: 90px;
$header-height-mobile: 70px;
$form-width: 600px;
$sidebar-width: 120px;
$sidebar-width-mobile: 300px;

//BEGIN LUMI COLORS
$lumi-pink: #fd55d8;
$lumi-purple: #6245b5;
$lumi-typography: #00234b; // this is lumi typography
$lumi-yellow: #ffbc3b;

// lumi grey
$lumi-primary-grey: #5d7287;
$lumi-subheading-grey: #8fa6aa;
$lumi-label-grey: #486586;
$lumi-secondary-grey: #818e9a;
$lumi-light-grey: #f5f5f7;

$lumi-border-color: #dedee2;
$lumi-background: #f8f9fb;
$lumi-light-pink: #fdecfa;
$lumi-hover-pink: #d223b1;
$lumi-glacier-grey: #e7edf5;
$lumi-hover-purple: #553c9a;

$lumi-pink-900: #6b125a;
$lumi-pink-800: #8f1878;
$lumi-pink-700: #b01e94;
$lumi-pink-600: #d223b1; // this is dark pink
$lumi-pink-500: #fd55d8; // this is lumi pink
$lumi-pink-400: #ff7de4;
$lumi-pink-300: #ffa6ec;
$lumi-pink-200: #ffd4f6; // this is light pink
$lumi-pink-100: #fff5fd;

$lumi-purple-900: #473183;
$lumi-purple-800: #553c9a;
$lumi-purple-700: #6245b5; // this is lumi purple
$lumi-purple-600: #7552d9;
$lumi-purple-500: #8e6cf0;
$lumi-purple-400: #a588f7;
$lumi-purple-300: #b9a1ff;
$lumi-purple-200: #dbcfff;
$lumi-purple-100: #f0ebff; // this is bg purple

$lumi-typography-900: #1c324a; // this is lumi typography
$lumi-typography-800: #3c5572; // this is lumi typography
$lumi-typography-700: #516985; // this is lumi typography
$lumi-typography-600: #768ba2;
$lumi-typography-500: #768ba2;
$lumi-typography-400: #becbda;
$lumi-typography-300: #d5e0eb;
$lumi-typography-200: #e5edf6;
$lumi-typography-100: #f8fafc;

$lumi-success-700: #145239;
$lumi-success-600: #197741;
$lumi-success-500: #259d58;
$lumi-success-400: #38c172;
$lumi-success-300: #74d99f;
$lumi-success-200: #a8eec1;
$lumi-success-100: #e3fcec;

$lumi-warning-700: #5c4813;
$lumi-warning-600: #8c6d1f;
$lumi-warning-500: #caa53d;
$lumi-warning-400: #f4ca64;
$lumi-warning-300: #fae29f;
$lumi-warning-200: #fdf3d7;
$lumi-warning-100: #fffcf4;

$lumi-error-700: #611818;
$lumi-error-600: #891b1b;
$lumi-error-500: #b82020;
$lumi-error-400: #dc3030;
$lumi-error-300: #e46464;
$lumi-error-200: #f5aaaa;
$lumi-error-100: #fce8e8;

$lumi-grey-900: #1a202c;
$lumi-grey-800: #2d3748;
$lumi-grey-700: #4a5568;
$lumi-grey-600: #718096;
$lumi-grey-500: #a0aec0;
$lumi-grey-400: #cbd5e0;
$lumi-grey-300: #e2e8f0;
$lumi-grey-200: #eef0f5;
$lumi-grey-100: #f8f9fb;

:export {
  lumiBodyFont: $lumi-body-font;
  lumiAvenirNextFont: $lumi-avenir-next-font;

  lumiPink: $lumi-pink;
  lumiPurple: $lumi-purple;
  lumiTypography: $lumi-typography; // this is lumi typography
  lumiYellow: $lumi-yellow;
  lumiPrimaryGrey: $lumi-primary-grey;
  lumiSecondaryGrey: $lumi-secondary-grey;
  lumiLightGrey: $lumi-light-grey;
  lumiBorderColor: $lumi-border-color;
  lumiBackground: $lumi-background;
  lumiLightPink: $lumi-light-pink;
  lumiHoverPink: $lumi-hover-pink;
  lumiHoverPurple: $lumi-hover-purple;

  lumiPink900: $lumi-pink-900;
  lumiPink800: $lumi-pink-800;
  lumiPink700: $lumi-pink-700;
  lumiPink600: $lumi-pink-600;
  lumiPink500: $lumi-pink-500;
  lumiPink400: $lumi-pink-400;
  lumiPink300: $lumi-pink-300;
  lumiPink200: $lumi-pink-200;
  lumiPink100: $lumi-pink-100;

  lumiPurple900: $lumi-purple-900; // this is lumi purple
  lumiPurple800: $lumi-purple-800;
  lumiPurple700: $lumi-purple-700;
  lumiPurple600: $lumi-purple-600;
  lumiPurple500: $lumi-purple-500;
  lumiPurple400: $lumi-purple-400;
  lumiPurple300: $lumi-purple-300;
  lumiPurple200: $lumi-purple-200;
  lumiPurple100: $lumi-purple-100;

  lumiTypography700: $lumi-typography-700; // this is lumi typography
  lumiTypography600: $lumi-typography-600;
  lumiTypography500: $lumi-typography-500;
  lumiTypography400: $lumi-typography-400;
  lumiTypography300: $lumi-typography-300;
  lumiTypography200: $lumi-typography-200;
  lumiTypography100: $lumi-typography-100;

  lumiSuccess700: $lumi-success-700;
  lumiSuccess600: $lumi-success-600;
  lumiSuccess500: $lumi-success-500;
  lumiSuccess400: $lumi-success-400;
  lumiSuccess300: $lumi-success-300;
  lumiSuccess200: $lumi-success-200;
  lumiSuccess100: $lumi-success-100;

  lumiWarning700: $lumi-warning-700;
  lumiWarning600: $lumi-warning-600;
  lumiWarning500: $lumi-warning-500;
  lumiWarning400: $lumi-warning-400;
  lumiWarning300: $lumi-warning-300;
  lumiWarning200: $lumi-warning-200;
  lumiWarning100: $lumi-warning-100;

  lumiError700: $lumi-error-700;
  lumiError600: $lumi-error-600;
  lumiError500: $lumi-error-500;
  lumiError400: $lumi-error-400;
  lumiError300: $lumi-error-300;
  lumiError200: $lumi-error-200;
  lumiError100: $lumi-error-100;

  lumiGrey900: $lumi-grey-900;
  lumiGrey800: $lumi-grey-800;
  lumiGrey700: $lumi-grey-700;
  lumiGrey600: $lumi-grey-600;
  lumiGrey500: $lumi-grey-500;
  lumiGrey400: $lumi-grey-400;
  lumiGrey300: $lumi-grey-300;
  lumiGrey200: $lumi-grey-200;
  lumiGrey100: $lumi-grey-100;
}

$pulse-background: #fbfcfe;

//BEGIN LEGACY SAIL COLORS
$sail-purple: #693393;
$sail-purple-dark: #4f1c76;
$sail-purple-dark-border: #57267c;
$sail-purple-hover: darken(#693393, 5%);
$sail-burnt-yellow-hover: #fda102;
$sail-burnt-yellow: #ffb400;
$sail-overlay-color: rgba($sail-purple, 0.95);
$sail-purple-shadow: #501f79;

$sail-soft-purple: #c76fc0;
$sail-orange: #ff7826;
$sail-blue: #458ee0;
$sail-turqoise: #11cfc9;
$sail-turqoise-background: #eafaf9;
$sail-turqoise-hover: #09b9b3;
$sail-teal: #06afb5;

$sail-amber-bg: #fefae5;
$sail-amber-border: #e7ddc4;
$sail-amber: #f09800;
// $sail-red: #e21b6b;
$sail-red: #df4280;
$sail-green: #07d03b;
$sail-disabled: #a8a8a8;

$sail-light-turqoise: #9df4ee;
$sail-dark: #283136;
$sail-grey: #787c85;
$sail-light-grey: #f4f4f4;
$sail-light-grey-background: #eeeeef;
$sail-middle-grey: #afb1b6;

$sail-danger: #c83411;

$sail-error: #e80f70;
$sail-warning: #faf2cc;
$sail-info: #bcdff1;
$sail-success: #d0e9c6;

$sail-divider-color: #e1e0e0;

$sail-dot-color: #c9d3dc;

$sail-bullet-color: #d7dde2;
$sail-border-color: #525d7d;

$sail-input: #5f6784;
$sail-placeholder-text: #d2d4d9;
$sail-light-purple-text: #a585be;

$sail-nearly-white: #f4f4f4;
$sail-light-grey-bg: #f8f8f8;
$sail-disabled-text: #d3d7e0;
$sail-white: #fff;
$sail-text: #505153;
$sail-text-grey: #7b808d;
$sail-text-light-grey: #5e6784;
$sail-text-nearly-white: #cdcdcd;
$sail-text-title-grey: #787c85;
$sail-muted-text: #6b748d;
$sail-border-grey: #dbdbe2;

$sail-sub-heading-color: #525d7c;

$sail-text-shadow-grey: #dfdee5;

$sail-shadow-color: #7f8186;

$sail-light-border-color: #dfdfe6;

$sail-table-border-color: #dfdfe6;
$sail-table-purple-border-color: #915db8;

.shadow {
  box-shadow: 0 3px 0 $lumi-grey-200;
}

.blurredShadow {
  box-shadow: $lumi-grey-200 0px 2px 4px 0px;
}

.shadowSharp1 {
  box-shadow: $lumi-grey-200 0px 4px 4px 0px;
}

.shadowSharp2 {
  box-shadow: $lumi-grey-200 0px 2px 2px 0px;
}

.shadowSharp3 {
  box-shadow: $lumi-grey-200 0px 2px 4px 0px;
}

.shadowBlur1 {
  box-shadow: 0px 0px 5px $lumi-grey-200;
}

.shadowBlur2 {
  box-shadow: 0px 0px 10px $lumi-grey-200;
}

.shadowBlur3 {
  box-shadow: 0px 0px 20px $lumi-grey-200;
}

.shadowBlur4 {
  box-shadow: 0px 4px 20px $lumi-grey-200;
}

.shadowDarkBlur1 {
  box-shadow: 0px 0px 5px $lumi-grey-300;
}

.shadowDarkBlur2 {
  box-shadow: 0px 0px 10px $lumi-grey-300;
}

.shadowDarkBlur3 {
  box-shadow: 0px 0px 20px $lumi-grey-300;
}

.shadowDarkBlur4 {
  box-shadow: 0px 4px 20px $lumi-grey-300;
}
