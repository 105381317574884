@import '../_variables';
@import '../layout/_flex';

:global {
  .ReactTable {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: $lumi-light-grey;

    .-btn {
      background: $lumi-typography !important;
      color: #fff !important;
    }

    .rt-tr-group .rt-tr {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: lighten($lumi-pink, 30%) !important;
      }
    }

    &.-striped .rt-tbody .rt-tr.-odd {
      background-color: rgba(251, 251, 251, 0.8) !important;
    }

    .rt-tbody {
      background-color: #fff;
    }

    .rt-thead {
      .rt-th {
        color: $lumi-typography;
        outline: none;
        padding-left: 20px !important;
      }
    }
  }

  .ReactTable.-striped .rt-tr.-odd {
    background-color: rgba($lumi-light-grey, 0.8) !important;
    &:hover {
      background: lighten($lumi-pink, 30%) !important;
    }
  }
}

.cellStyle {
  @extend .flexAlignCenter;
  height: 50px;
  font-family: $lumi-body-font;
  font-size: 16px;
  padding: 16px 24px !important;

  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
}

.headerStyle {
  @extend .flexAlignCenter;
  font-family: $lumi-body-medium-font;
  font-size: 16px !important;
  text-align: left;
  padding: 16px 24px !important;
  outline: none;
  overflow: visible !important;
}

.rightAlign {
  justify-content: flex-end;
}

.customTable {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background-color: $lumi-light-grey;
  display: grid;
  grid-auto-flow: row;
  border: solid #dedee2 1px;
  overflow-y: auto;

  .row {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    grid-auto-columns: 1fr;
    grid-gap: 0px;
  }
}
