@import '../../../common/sass/core_base.scss';

.container {
  position: fixed;
  height: $header-height;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-bottom: solid $lumi-light-grey 1px;
  background: #fff;
  @media (max-width: 768px) {
    position: relative;
  }
}

.inner {
  display: flex;
  max-width: $body-max-width;
  margin: 0 auto;
  height: $header-height;
  align-items: center;
  padding: 0 20px;
}

.progress {
  flex-grow: 1;
}

.login,
.logo {
  flex: 1;
}

.logo svg {
  display: inline-block;
  width: 100px;
  margin-right: 15px;
  @media (min-width: 992px) {
    width: 150px;
  }
}

.continue {
  color: $lumi-pink;
  font-size: 14px;
  font-family: $lumi-body-font;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 12px;
    max-width: 160px;
  }
  span {
    transition: 0.3s ease;
  }
  &:hover {
    color: darken($lumi-typography, 10%);
    span {
      color: darken(white, 10%);
    }
  }
  i {
    margin-left: 7px;
    transform: rotate(-90deg);
    display: inline-block;
    font-size: 8px;
    position: relative;
    top: -2px;
  }

  @media (max-width: 768px) {
    br {
      display: none;
    }
    i {
      margin-left: 4px;
    }
  }
  @media screen and (max-width: 330px) {
    i {
      display: none;
    }
  }
  span {
    font-size: 16px;
    color: $lumi-purple;
    font-weight: 800;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
