$xs-breakpoint-max-width: 768px;
$sm-breakpoint-min-width: 768px;
$md-breakpoint-min-width: 992px;

$img-path: '/public/imgs/common';
$font-path: '/public/fonts';

// Font
$sail-heading-font: filson-soft, san-serif;
$sail-body-font: stevie-sans, sans-serif;

$lumi-heading-font: AvenirNext, san-serif;
$lumi-body-font: AvenirNext, san-serif;
$lumi-body-medium-font: AvenirNextLTPro-Medium, san-serif;
$lumi-subheading-font: AvenirNextLTPro-Demi, san-serif;
$lumi-italic-font: AvenirNext, san-serif;

$body-max-width: 1440px;
$input-font-size: 20px;

$header-height: 90px;
$header-height-mobile: 70px;
$form-width: 600px;
$sidebar-width: 120px;
$sidebar-width-mobile: 300px;

//BEGIN LUMI COLORS
$lumi-pink: #fd55d8;
$lumi-purple: #6245b5;
$lumi-yellow: #ffbc3b;

$lumi-typography: #00234b;
$lumi-primary-grey: #5d7287;
$lumi-secondary-grey: #818e9a;
$lumi-border-color: #dedee2;
$lumi-light-grey: #f5f5f7;
$lumi-background: #f8f9fb;

$lumi-light-pink: #ffd4f6;

$sail-purple: #693393;
$sail-purple-dark: #4f1c76;
$sail-purple-dark-border: #57267c;
$sail-purple-hover: darken(#693393, 5%);
$sail-burnt-yellow-hover: #fda102;
$sail-burnt-yellow: #ffb400;
$sail-overlay-color: rgba($sail-purple, 0.95);
$sail-purple-shadow: #501f79;

$sail-soft-purple: #c76fc0;
$sail-orange: #ff7826;
$sail-blue: #458ee0;
$sail-turqoise: #11cfc9;
$sail-turqoise-background: #eafaf9;
$sail-turqoise-hover: #09b9b3;
$sail-teal: #06afb5;

$sail-amber-bg: #fefae5;
$sail-amber-border: #e7ddc4;
$sail-amber: #f09800;
$sail-red: #e21b6b;
$sail-green: #07d03b;
$sail-disabled: #a8a8a8;

$sail-light-turqoise: #9df4ee;
$sail-dark: #283136;
$sail-grey: #787c85;
$sail-light-grey: #f4f4f4;
$sail-light-grey-background: #eeeeef;
$sail-middle-grey: #afb1b6;

$sail-danger: #c83411;

$sail-error: #e80f70;
$sail-warning: #faf2cc;
$sail-info: #bcdff1;
$sail-success: #d0e9c6;

$sail-divider-color: #e1e0e0;

$sail-dot-color: #c9d3dc;

$sail-bullet-color: #d7dde2;
$sail-border-color: #525d7d;

$sail-input: #5f6784;
$sail-placeholder-text: #d2d4d9;
$sail-light-purple-text: #a585be;

$sail-nearly-white: #f4f4f4;
$sail-light-grey-bg: #f8f8f8;
$sail-disabled-text: #d3d7e0;
$sail-white: #fff;
$sail-text: #505153;
$sail-text-grey: #7b808d;
$sail-text-light-grey: #5e6784;
$sail-text-nearly-white: #cdcdcd;
$sail-text-title-grey: #787c85;
$sail-muted-text: #6b748d;
$sail-border-grey: #dbdbe2;

$sail-sub-heading-color: #525d7c;

$sail-text-shadow-grey: #dfdee5;

$sail-shadow-color: #7f8186;

$sail-light-border-color: #dfdfe6;

$sail-table-border-color: #dfdfe6;
$sail-table-purple-border-color: #915db8;
