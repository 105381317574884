@import '../variables';

html,
body {
  font-family: $lumi-body-font;
  text-rendering: geometricPrecision;
  overflow-x: hidden;
  margin: 0;
}

hr {
  border: 0;
  height: 3px;
  background-color: $sail-light-grey;
}

.shadow {
  box-shadow: 0 3px 0 $lumi-grey-200;
}

.blurredShadow {
  box-shadow: $lumi-grey-200 0px 2px 4px 0px;
}

.shadowSharp1 {
  box-shadow: $lumi-grey-200 0px 4px 4px 0px;
}

.shadowSharp2 {
  box-shadow: $lumi-grey-200 0px 2px 2px 0px;
}

.shadowSharp3 {
  box-shadow: $lumi-grey-200 0px 2px 4px 0px;
}

.shadowBlur1 {
  box-shadow: 0px 0px 5px $lumi-grey-200;
}

.shadowBlur2 {
  box-shadow: 0px 0px 10px $lumi-grey-200;
}

.shadowBlur3 {
  box-shadow: 0px 0px 20px $lumi-grey-200;
}

.shadowBlur4 {
  box-shadow: 0px 4px 20px $lumi-grey-200;
}

.shadowDarkBlur1 {
  box-shadow: 0px 0px 5px $lumi-grey-300;
}

.shadowDarkBlur2 {
  box-shadow: 0px 0px 10px $lumi-grey-300;
}

.shadowDarkBlur3 {
  box-shadow: 0px 0px 20px $lumi-grey-300;
}

.shadowDarkBlur4 {
  box-shadow: 0px 4px 20px $lumi-grey-300;
}

.nakedLink {
  font-family: $lumi-body-medium-font;
  color: $lumi-pink-700;
  font-size: 16px;
  cursor: pointer;
}

.tableLayout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tableLayoutHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5%;
    min-height: 50px;
    width: 100%;

    input {
      font-size: 14px;
      width: 300px;
    }

    label {
      font-size: 14px;
    }

    .tableLayoutActions {
      display: flex;
      max-height: 35px;

      .tableLayoutSearch {
        padding-left: 20px;
      }
    }
  }
  .tableLayoutTable {
    display: flex;
    flex: 1;
    width: 100%;
  }
}

.modalWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 700px;
  background: $lumi-light-grey;
  z-index: 2;
}

.smallModalWrapper {
  position: fixed;
  top: 25%;
  left: 15%;
  width: 75%;
  height: 50%;
  overflow: auto;
  background: $lumi-light-grey;
  z-index: 2;
  border-radius: 10px;
}

.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  outline: none;
}

.formLayout {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h4 {
    margin-top: 35px;
    grid-column: 1 / span 2;
    color: $lumi-purple;
  }

  .formGrid {
    width: 100%;
    min-width: 600px;
    margin-bottom: 50px;

    .formInput {
      position: relative;
      margin: 30px 30px 30px 0;
    }
  }

  :not([class]) {
    width: 50%;
  }
}

@for $i from 0 through 12 {
  .mt-#{$i} {
    margin-top: 5px * $i;
  }

  .mr-#{$i} {
    margin-right: 5px * $i;
  }

  .mb-#{$i} {
    margin-bottom: 5px * $i;
  }

  .ml-#{$i} {
    margin-left: 5px * $i;
  }

  .m-#{$i} {
    margin: 5px * $i;
  }

  .pt-#{$i} {
    padding-top: 5px * $i;
  }

  .pr-#{$i} {
    padding-right: 5px * $i;
  }

  .pb-#{$i} {
    padding-bottom: 5px * $i;
  }

  .pl-#{$i} {
    padding-left: 5px * $i;
  }

  .p-#{$i} {
    padding: 5px * $i;
  }
}

.tabularDataLayout {
  background: #fff;
  border-radius: 10px;
  padding: 16px 0;
  margin-top: 26px;
  box-shadow: 0 5px 15px #dfe3e6, 0 4px 25px #f7f9fa;

  .tabularDataContainer {
    display: flex;
    flex-direction: column;
    h3 {
      padding: 0 24px;
    }

    .tabularDataRows {
      span,
      label {
        display: flex;
        align-items: center;
        margin: 8px 0;
        padding: 0 24px;
        font-family: $lumi-subheading-font;
        font-weight: normal;
      }
      span {
        color: $lumi-typography-800;
        font-family: $lumi-body-font;
      }
      label {
        color: $lumi-typography;
      }
      & > :nth-child(even) {
        background: $lumi-grey-100;
      }

      .tabularDataRow {
        &.total {
          span,
          label {
            font-family: $lumi-subheading-font;
          }
        }

        &.nested {
          padding-left: 40px;
        }
        .editable-row {
          padding: 0 24px;
        }
        grid-column: span 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
