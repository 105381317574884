@import '../variables';

.container {
  margin-bottom: 50px;
  & > label {
    display: block;
    text-align: left;
    margin-bottom: 15px;
    span {
      font-size: 14px;
      opacity: 0.4;
      margin-left: 10px;
      display: inline-block;
    }
  }
  label {
    color: $lumi-typography;
    font-weight: 600;
    font-size: $input-font-size;
    font-family: $sail-body-font;
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  flex: 1;
  &:first-child {
    margin-right: 20px;
  }
  @media screen and (max-width: $xs-breakpoint-max-width) {
    margin-top: 10px;
    margin-bottom: 10px;
    &:first-child {
      margin-right: 0px;
    }
  }
  div {
    display: inline-block;
    margin-right: 0;
    &:before {
      background-color: $lumi-secondary-grey;
    }
  }
  label {
    background-color: white;
    cursor: pointer;
    border-color: $lumi-pink;
    transition: background-color 0.2s;
    &:hover {
      background-color: rgba($lumi-pink, 0.8);
      color: #fff;
    }
  }
  input:checked + label {
    background-color: $lumi-purple;
    border-color: $lumi-purple;
    color: #fff;
  }
  input {
    display: none;
  }
}
.picker {
  display: flex;
  @media screen and (max-width: $xs-breakpoint-max-width) {
    display: block;
    flex-direction: column;
  }
}
