@import '../core_base';

.container {
  display: flex;
  align-items: center;
  padding: 20px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .indicator {
    color: $lumi-purple;
    margin-right: 10px;
    text-align: center;
    width: 25px;
  }
  .label {
    display: flex;
    align-items: center;
    font-family: $lumi-heading-font;
    font-size: 20px;
    color: $lumi-typography;
    flex-grow: 1;
    text-align: left;

    .subLabel {
      font-size: 14px;
      color: rgba($sail-text-light-grey, 0.4);
      margin-left: 10px;
      display: inline-block;
    }
  }
  .bullet {
    width: 7px;
    height: 7px;
    background-color: $sail-border-grey;
    border-radius: 5px;
    display: inline-block;
  }
}
