@import '../../../common/sass/core_base';

.wrapper {
  display: flex;
}
.body {
  flex-grow: 1;
  max-width: 100%;
}

.progressContainer {
  margin-left: 30px;
  display: none;
  @media (min-width: 992px) {
    display: block;
    flex-basis: 250px;
    max-width: 250px;
  }
  @media (min-width: 1200px) {
    flex-basis: 295px;
    max-width: 295px;
  }
  & > div {
    margin-bottom: 30px;
  }
}

.progress {
  @media (min-width: 768px) {
    display: none;
  }
}
