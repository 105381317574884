@import '../core_base';

.card {
  padding: 30px;
  border: solid $lumi-border-color 1px;
  border-radius: 10px;
  background-color: $sail-white;
  position: relative;

  &.small {
    padding: 10px 15px;
    max-width: 350px;
    min-width: 350px;
    min-height: 125px;
    border-radius: 5px;
  }

  &.clickable {
    transition: 0.2s ease;
    cursor: pointer;
    &:hover {
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    }
  }
}

.tabCard {
  @extend .card;
  padding: 25px;
  text-align: left;
  margin-right: 10px;
  cursor: pointer;
  min-width: 350px;
  margin-bottom: 10px;
}

.active {
  border: 2px solid $lumi-pink;
}

.editing {
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 10px;
    height: 10px;
    background-color: $lumi-pink;
    border-radius: 5px;
  }
}

.pageCard {
  padding: 20px;
  border: solid $lumi-border-color 1px;
  background-color: $sail-white;
  max-width: 100%;
  position: relative;

  @media (min-width: 992px) {
    padding: 50px;
    border-radius: 10px;
    border: solid $lumi-border-color 1px;
  }

  h3 {
    color: $lumi-typography-800;
  }
}

.dark {
  background-color: $sail-light-grey-background;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.amber {
  background-color: $sail-amber-bg;
  border-color: $sail-amber-border;
  padding: 30px;
  line-height: 1.5;
}

.iconCard {
  display: flex;
  border: solid $lumi-border-color 1px;
  .title {
    flex-grow: 1;
    flex: 1;
    @media (max-width: 768px) {
      h3,
      h1 {
        max-width: 250px;
        margin: 5px auto;
      }
    }
  }

  .cash {
    position: relative;
    @media (min-width: 992px) {
      width: 120px;
      top: -10px;
    }
    @media (min-width: 768px) {
      width: 100px;
      top: 0;
    }
    div {
      transform: rotate(12deg);
      @media (max-width: 768px) {
        position: absolute;
        left: -70px;
      }
      svg {
        width: 80px;
        height: 80px;
        @media (min-width: 992px) {
          width: 150px;
          height: 150px;
        }
        @media (min-width: 768px) {
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  .coins {
    position: relative;
    top: -15px;
    @media (min-width: 768px) {
      top: 0;
    }
    @media (min-width: 992px) {
      width: 120px;
    }
    .one {
      position: absolute;
      top: -40px;
      right: 40px;
      svg {
        width: 60px;
        height: 60px;
      }
    }
    .two {
      position: absolute;
      top: 25px;
      right: -5px;
      transform: rotate(-10deg);
      svg {
        width: 55px;
        height: 55px;
      }
    }
    .three {
      position: absolute;
      top: 80px;
      right: 60px;
      transform: rotate(10deg);
      svg {
        width: 45px;
        height: 45px;
      }
    }
    @media (max-width: 768px) {
      // left: -25px;
      .one {
        top: 20px;
        right: -40px;
        svg {
          width: 35px;
          height: 35px;
        }
      }
      .two {
        top: 60px;
        right: -65px;
        svg {
          width: 35px;
          height: 35px;
        }
      }
      .three {
        top: 90px;
        right: -20px;
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.content {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.iconButton {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .icon {
    height: 70px;
  }

  i {
    font-size: 50px;
    color: $lumi-pink-800;
    font-weight: 600;
  }
}
