@import '../variables';

.fileName {
  font-size: 14px;
  color: $lumi-typography;
  font-family: $lumi-heading-font;
  display: flex;
  align-items: center;
  font-weight: 600;
  label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 220px;
  }
  i {
    margin-left: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      color: darken($sail-text-light-grey, 10%);
    }
  }
}

.disabled {
  pointer-events: none;
  label {
    background-color: $lumi-light-grey;
  }
}

.error-text {
  margin-top: 15px;
  margin-left: 5px;
}
