@import '../../../common/sass/variables';

.searchIcon {
  padding-right: 8px;
  color: $lumi-pink-700;
}

.abnCheckLabel {
  color: $lumi-pink-700;
  // Smart Phones
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
}

.abrLink {
  position: absolute;
  bottom: 44px;
  right: -8px;
  width: 300px;
  text-align: right;
  a {
    font-size: 14px;
    color: $lumi-purple;
    font-family: $lumi-subheading-font;
  }
}

.abnLoader {
  position: absolute;
  right: 1px;
  top: 4px;
}

.companyStakeholderForm {
  .abrLink {
    bottom: 76px;
    right: 0;
  }
}
