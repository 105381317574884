@import '../variables';

.header {
  padding: 15px 40px;

  .label {
    h2 {
      font-size: 45px;
      font-weight: 700;
      margin-right: 10px;
      display: inline-block;
    }
    span {
      font-weight: 600;
      color: $lumi-primary-grey;
    }
  }
}

.checklist {
  margin: 40px 0;
  padding: 0 40px;
}
