@font-face {
  font-family: 'lumi-icon';
  src: url('~/../common/sass/icons/fonts/sail-icon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.lumi-icon,
[class^='lumi-icon'],
[class^='_icon-'],
[class^='icon-'],
[class*='lumi-icon'] {
  /* use !important to prevent issues with browser extensions that change fonts */

  &:before {
    font-family: 'lumi-icon' !important;
  }

  font-family: 'lumi-icon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-margin {
  margin-right: 7px;
}
.icon-check-circle:before {
  content: '\e957';
}
.icon-calendar-large:before {
  content: '\e955';
}
.icon-rocket:before {
  content: '\e956';
}
.icon-exclaimation-triangle:before {
  content: '\e954';
}
.icon-closed:before {
  content: '\e94f';
}
.icon-declined:before {
  content: '\e950';
}
.icon-funded:before {
  content: '\e951';
}
.icon-processing:before {
  content: '\e952';
}
.icon-account .path1:before {
  content: '\e909';
  color: rgb(157, 243, 240);
}
.icon-account .path2:before {
  content: '\e90a';
  margin-left: -0.6953125em;
  color: rgb(157, 243, 240);
}
.icon-account .path3:before {
  content: '\e90b';
  margin-left: -0.6953125em;
  color: rgb(224, 223, 230);
}
.icon-account .path4:before {
  content: '\e90c';
  margin-left: -0.6953125em;
  color: rgb(224, 223, 230);
}
.icon-account .path5:before {
  content: '\e90d';
  margin-left: -0.6953125em;
  color: rgb(224, 223, 230);
}
.icon-account .path6:before {
  content: '\e90e';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path7:before {
  content: '\e90f';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path8:before {
  content: '\e910';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path9:before {
  content: '\e911';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path10:before {
  content: '\e912';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path11:before {
  content: '\e913';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path12:before {
  content: '\e914';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path13:before {
  content: '\e915';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path14:before {
  content: '\e916';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path15:before {
  content: '\e917';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-account .path16:before {
  content: '\e918';
  margin-left: -0.6953125em;
  color: rgb(82, 93, 125);
}
.icon-add:before {
  content: '\e919';
}
.icon-application:before {
  content: '\e91a';
}
.icon-await:before {
  content: '\e91b';
}
.icon-calendar .path1:before {
  content: '\e91c';
  color: rgb(157, 243, 240);
}
.icon-calendar .path2:before {
  content: '\e91d';
  margin-left: -1.009765625em;
  color: rgb(82, 93, 125);
}
.icon-calendar .path3:before {
  content: '\e91e';
  margin-left: -1.009765625em;
  color: rgb(82, 93, 125);
}
.icon-calendar .path4:before {
  content: '\e91f';
  margin-left: -1.009765625em;
  color: rgb(82, 93, 125);
}
.icon-calendar .path5:before {
  content: '\e920';
  margin-left: -1.009765625em;
  color: rgb(82, 93, 125);
}
.icon-call:before {
  content: '\e921';
}
.icon-card:before {
  content: '\e922';
}
.icon-cash .path1:before {
  content: '\e923';
  color: rgb(224, 223, 230);
}
.icon-cash .path2:before {
  content: '\e924';
  margin-left: -1.52734375em;
  color: rgb(224, 223, 230);
}
.icon-cash .path3:before {
  content: '\e925';
  margin-left: -1.52734375em;
  color: rgb(224, 223, 230);
}
.icon-cash .path4:before {
  content: '\e926';
  margin-left: -1.52734375em;
  color: rgb(157, 243, 240);
}
.icon-cash .path5:before {
  content: '\e927';
  margin-left: -1.52734375em;
  color: rgb(157, 243, 240);
}
.icon-cash .path6:before {
  content: '\e928';
  margin-left: -1.52734375em;
  color: rgb(82, 93, 125);
}
.icon-cash .path7:before {
  content: '\e929';
  margin-left: -1.52734375em;
  color: rgb(82, 93, 125);
}
.icon-cash .path8:before {
  content: '\e92a';
  margin-left: -1.52734375em;
  color: rgb(82, 93, 125);
}
.icon-cash .path9:before {
  content: '\e92b';
  margin-left: -1.52734375em;
  color: rgb(82, 93, 125);
}
.icon-cash .path10:before {
  content: '\e92c';
  margin-left: -1.52734375em;
  color: rgb(157, 243, 240);
}
.icon-cash .path11:before {
  content: '\e92d';
  margin-left: -1.52734375em;
  color: rgb(82, 93, 125);
}
.icon-cash .path12:before {
  content: '\e92e';
  margin-left: -1.52734375em;
  color: rgb(82, 93, 125);
}
.icon-cash .path13:before {
  content: '\e92f';
  margin-left: -1.52734375em;
  color: rgb(82, 93, 125);
}
.icon-clipboard .path1:before {
  content: '\e930';
  color: rgb(82, 93, 125);
}
.icon-clipboard .path2:before {
  content: '\e931';
  margin-left: -0.958984375em;
  color: rgb(82, 93, 125);
}
.icon-clipboard .path3:before {
  content: '\e932';
  margin-left: -0.958984375em;
  color: rgb(82, 93, 125);
}
.icon-clipboard .path4:before {
  content: '\e933';
  margin-left: -0.958984375em;
  color: rgb(157, 243, 240);
}
.icon-clipboard .path5:before {
  content: '\e934';
  margin-left: -0.958984375em;
  color: rgb(82, 93, 125);
}
.icon-clock:before {
  content: '\e935';
}
.icon-close:before {
  content: '\e936';
}
.icon-close2:before {
  content: '\e937';
}
.icon-document:before {
  content: '\e938';
}
.icon-download:before {
  content: '\e939';
}
.icon-dropdown:before {
  content: '\e93a';
}
.icon-exit:before {
  content: '\e93b';
}
.icon-expand:before {
  content: '\e93c';
}
.icon-eye:before {
  content: '\e93d';
}
.icon-filter .path1:before {
  content: '\e93e';
  color: rgb(82, 93, 125);
}
.icon-filter .path2:before {
  content: '\e93f';
  margin-left: -1.41796875em;
  color: rgb(157, 243, 240);
}
.icon-filter .path3:before {
  content: '\e940';
  margin-left: -1.41796875em;
  color: rgb(82, 93, 125);
}
.icon-filter .path4:before {
  content: '\e941';
  margin-left: -1.41796875em;
  color: rgb(82, 93, 125);
}
.icon-filter .path5:before {
  content: '\e942';
  margin-left: -1.41796875em;
  color: rgb(82, 93, 125);
}
.icon-filter .path6:before {
  content: '\e943';
  margin-left: -1.41796875em;
  color: rgb(157, 243, 240);
}
.icon-filter .path7:before {
  content: '\e944';
  margin-left: -1.41796875em;
  color: rgb(82, 93, 125);
}
.icon-filter .path8:before {
  content: '\e945';
  margin-left: -1.41796875em;
  color: rgb(82, 93, 125);
}
.icon-filter-simple:before {
  content: '\e946';
}
.icon-logout:before {
  content: '\e947';
}
.icon-missed:before {
  content: '\e948';
}
.icon-nav:before {
  content: '\e949';
}
.icon-plus:before {
  content: '\e94a';
}
.icon-schedule:before {
  content: '\e94b';
}
.icon-search:before {
  content: '\e94c';
}
.icon-search-simple:before {
  content: '\e94d';
}
.icon-upload:before {
  content: '\e94e';
}
.icon-home:before {
  content: '\e908';
}
.icon-social-youtube:before {
  content: '\e900';
}
.icon-social-twitter:before {
  content: '\e901';
}
.icon-social-linkedin:before {
  content: '\e902';
}
.icon-social-facebook:before {
  content: '\e903';
}
.icon-phone:before {
  content: '\e904';
}
.icon-mobile-nav:before {
  content: '\e905';
}
.icon-mobile-cross:before {
  content: '\e906';
}
.icon-login:before {
  content: '\e907';
}
