@import '../variables';
@import '../layout/flex';

$size: 30px;
$speed: 0.75s;
$thickness: 3px;

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: $size;
  height: $size;
  border: $thickness solid $sail-white;
  border-right-color: rgba($sail-white, 0.2);
  border-radius: 100px;
  display: inline-block;
  animation: rotate $speed infinite;
  animation-timing-function: ease-in-out;
}

.pink {
  border-color: $lumi-pink;
  border-right-color: $lumi-border-color;
}

.small {
  width: 20px;
  height: 20px;
}

.spinnerPink {
  @extend .pink;
}

.pageSpinner {
  @extend .pink;
  margin: 0 auto;
  display: block;
  width: 50px;
  height: 50px;
}

.haikuSize {
  width: 150px !important;
  height: 150px !important;
}

.spinnerContainer {
  @extend .flexCenter;
  width: 100%;
  height: 100%;
}
