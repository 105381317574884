@import '../../../../../common/sass/variables';

.fileUploadWrapper {
  margin: 32px auto 0 auto;
}
.confirmation {
  margin: 32px auto 0 auto;
}
.alreadyUploaded {
  padding-top: 16px;
  font-family: $lumi-subheading-font;
  color: $lumi-purple;
  font-size: 18px;
  text-align: center;
  width: 100%;
}
