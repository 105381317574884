@import '../../../../../common/sass/variables';

.genericUploadWrapper {
  margin: 8px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.alreadyUploaded {
  margin: 8px auto;
  font-family: $lumi-subheading-font;
  color: $lumi-purple;
  font-size: 18px;
  text-align: center;
  width: 100%;
}
