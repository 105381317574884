@import '../../../../../common/sass/variables';

.propertyQuestion {
  span {
    font-size: 18px;
    color: $lumi-typography;
    font-family: $lumi-subheading-font;
  }
}

.propertyLabel {
  margin-top: 4px;

  span {
    font-size: 18px;
  }
}
