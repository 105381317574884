@import '../../../common/sass/core_base';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $lumi-heading-font;
  & > div {
    display: flex;
    margin-right: 5px;
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    align-items: center;
    opacity: 0.2;
    i {
      display: none;
    }

    &.line {
      opacity: 1;
      svg {
        width: 80px;
        height: 15px;

        path {
          stroke: $lumi-purple;
        }
      }
    }

    &.active {
      opacity: 1;
      .number {
        background-color: $lumi-purple;
      }
    }
    &.completed {
      opacity: 1;
      .number {
        color: $lumi-pink;
        border: solid $lumi-pink;
        background: #fff;
        font-size: 14px;
        line-height: 39px;
      }
      span {
        display: none;
      }
      i {
        display: inline-block;
      }
    }
    @media (max-width: 992px) {
      &.line {
        svg {
          width: 50px;
          height: 10px;
        }
      }
      & > div {
        margin-right: 0;
        &:before {
          top: 19px;
          left: 3px;
          width: 20px;
          height: 20px;
        }
        .number {
          width: 30px;
          height: 30px;
          font-size: 17px;
          line-height: 25px;
        }
        &:last-child {
          display: none;
          @media (min-width: 992px) {
            display: block;
          }
        }
      }
      &.completed {
        .number {
          font-size: 12px !important;
          line-height: 0 !important;
          i {
            position: relative;
            top: -2px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      &.line {
        svg {
          width: 20px;
          height: 5px;
        }
      }
      & > div {
        margin-right: 0;
        width: 30px;
        height: 30px;
        &:before {
          top: 10px;
          left: 0px;
          width: 15px;
          height: 15px;
        }
        .number {
          width: 25px;
          height: 25px;
          font-size: 14px;
          line-height: 20px;
        }
        &:last-child {
          display: none;
          @media (min-width: 992px) {
            display: block;
          }
        }
      }
      &.completed {
        .number {
          font-size: 10px !important;
          line-height: 0 !important;
          i {
            position: relative;
            top: -4px;
          }
        }
      }
    }
  }
}
