@import '../../../common/sass/core_base';

.header {
  height: $header-height-mobile;
  @media (min-width: 992px) {
    height: $header-height;
  }
  background-color: #fff;
  padding: 20px;
  display: flex;
  font-size: 14px;
  align-items: center;
  position: absolute;
  border-bottom: solid $lumi-light-grey 1px;
  top: 0;
  left: 0;
  right: 0;

  .logo {
    position: relative;
    display: inline-block;
    flex-grow: 1;
    text-align: center;
    width: $sidebar-width - 20px;

    @media (min-width: 992px) {
      flex-grow: 0;
      text-align: left;
    }

    svg {
      width: 100px;

      @media (min-width: 992px) {
        width: 150px;
      }
    }
  }

  .middle {
    display: none;
    margin-left: 100px;
    font-size: 18px;
    font-family: $lumi-heading-font;
    color: $lumi-typography;
    @media (min-width: 992px) {
      display: block;
      flex-grow: 1;
    }
  }

  .account {
    color: $lumi-typography;
    margin-right: 20px;
    font-weight: 600;
    text-align: right;
    display: none;

    @media (min-width: 992px) {
      display: block;
    }

    .accent {
      font-size: 12px;
      span {
        font-size: 14px;
        margin-right: 5px;
        position: relative;
        top: 2px;
      }
    }
  }

  .image {
    background-color: white;
    border: 2px solid darken($sail-purple, 10%);
    border-radius: 99px;
    overflow: hidden;
    svg {
      width: 32px;
      height: 32px;
      @media (min-width: 992px) {
        width: 48px;
        height: 48px;
      }
      top: 2px;
      position: relative;
    }
  }

  .nav {
    display: block;
    font-size: 24px;
    color: white;
    cursor: pointer;
    transition: 0.2s color ease;

    &:hover {
      color: darken($sail-white, 20%);
    }

    @media (min-width: 992px) {
      display: none;
    }
  }
}
