@import '../variables';
:global {
  .geosuggest__suggests {
    position: absolute;
    top: 45px;
    background-color: $lumi-purple;
    border: 1px;
    border-radius: 5px;
    color: white;
    z-index: 9;
    box-shadow: 0px 5px 10px 0px rgba($sail-shadow-color, 0.3);
    padding: 0;
    margin: 0;
    max-height: 250px;
    width: 100%;
    overflow-y: auto;

    li {
      list-style: none;
      text-align: left;
      transition: 0.3s ease;
      padding: 10px 30px;
      cursor: pointer;
      &:nth-child(even) {
        background-color: darken($lumi-purple, 2%);
      }
      &:hover {
        background-color: rgba($lumi-pink, 0.8);
      }
    }
  }
  .geosuggest__suggests--hidden {
    display: none;
  }
}
