@import '../layout/flex';
@import '../variables';

.lumiPicker {
  @extend .flexCenter;
  position: relative;
  background: linear-gradient(180deg, #fff, #f9fafb);
  border-radius: 4px;
  font-family: $lumi-body-medium-font;
  color: $lumi-typography-700;
  box-sizing: border-box;
  max-height: 39px;

  .pickerOption {
    @extend .flexCenter;
    cursor: pointer;
    padding: 6.5px 16px;
    border: solid $lumi-secondary-grey 1px;
    box-sizing: border-box;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-left: none;
    }

    &:hover {
      background: $lumi-grey-100;
    }

    &.selectedOption {
      border: solid $lumi-purple 2px !important;
      border-radius: 3px;
      border-right: none;
    }

    &.disabledOption {
      background: #edf2f7;
      color: $lumi-grey-600;
    }
  }

  .pickerOption:not(:last-child) {
    border-right: solid $lumi-secondary-grey 1px;
  }
}
.wrapAround {
  max-height: fit-content;
}

.error {
  top: -28px;
}
