@import '../variables';

.loadIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadIcon span {
  background: $lumi-purple;
  width: 12.5px;
  height: 12.5px;
  border-radius: 100px;
  margin: 0 3px;
}
.loadIcon span:nth-of-type(1) {
  left: 0;
}
.loadIcon span:nth-of-type(2) {
  left: 40%;
}
.loadIcon span:nth-of-type(3) {
  right: 0;
}

/* Animation */
.loadIcon span {
  animation-name: loading;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
.loadIcon span:nth-of-type(2) {
  animation-delay: 0.1s;
}
.loadIcon span:nth-of-type(3) {
  animation-delay: 0.2s;
}

/* Keyframes */
@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
