.flex {
  display: flex;
}

.flex1 {
  display: flex;
  flex: 1;
}

.flexGrow {
  display: flex;
  flex-grow: 1;
}

.flexShrink {
  display: flex;
  flex-shrink: 1;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.flexJustifyCenter {
  display: flex;
  justify-content: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
}
