@import '../../../../../common/sass/layout/flex';
@import '../../../../../common/sass/variables';
@import '../../../../../common/sass/components/card';

.requirementBullet {
  margin-top: 18px;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  color: $lumi-purple;
  font-family: $lumi-body-medium-font;

  &::before {
    margin: 8px 8px;
    position: relative;
    content: '';
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: $lumi-pink;
    border-radius: 20px;
  }
}

.field {
  margin: 32px 0;
}

.button {
  display: flex;
  justify-content: center;
}

.formContainer {
  max-width: 600px;
  margin: 0 auto;
  text-align: start;

  h1 {
    text-align: center;
  }

  span {
    color: $lumi-typography;
    font-size: 20px;
  }
}
