@import '../../sass/_variables.scss';

.radioButton {
  opacity: 0;
  & + label {
    cursor: pointer;
    padding-left: 30px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 25px;
      height: 25px;
      left: 0;
      text-align: center;
      line-height: 25px;
      border: 1px solid $lumi-grey-300;
      border-radius: 32px;
    }
  }
  &:checked + label {
    &:before {
      opacity: 1;
      background-color: $lumi-yellow;
      box-shadow: inset 0px 0px 0px 6px #fff;
    }
  }
  &:focus + label {
    &:before {
      border: 1px solid $lumi-pink;
    }
  }
}
