@import '../../../../../common/sass/variables';
@import '../../../../../common/sass/layout/flex';
@import '../../../../../common/sass/layout/layout';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 5px;
}

.alignLeft {
  display: flex;
  color: $lumi-typography;
  font-size: 18px;

  span {
    margin-right: 20px;
  }
}

h4 {
  color: $lumi-purple;
  margin: 0 0 0 auto;
  font-weight: normal;
  text-align: right;
}
.file {
  margin: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
  }
}

.notes {
  font-size: 18px;
  color: $lumi-purple;
}
