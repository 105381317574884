@import '../layout/flex';
@import '../variables';

.lumiPicker {
  @extend .flexCenter;
  position: relative;
  background: linear-gradient(180deg, #fff, #f9fafb);
  border-radius: 4px;
  font-family: $lumi-body-medium-font;
  color: $lumi-typography-700;
  box-sizing: border-box;
  max-height: 39px;
  top: 2px;

  .pickerOption {
    @extend .flexCenter;
    cursor: pointer;
    padding: 16.5px 16px;
    border: solid $lumi-secondary-grey 1px;
    border-color: #cbd5e0;
    border-bottom: 0;
    box-sizing: border-box;
    max-height: 20px;
    font-size: 12px;

    &:first-child {
      border-top-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
    }

    &:hover {
      background: $lumi-grey-100;
    }

    &.selectedOption {
      box-shadow: inset 0 0 0 2px $lumi-purple;
      color: $lumi-purple;
    }

    &.disabledOption {
      background: $lumi-grey-300;
      color: $lumi-grey-600;
    }
  }
}

.error {
  top: -28px;
}
