@import '../variables';

.flash {
  background-color: $lumi-purple;
  border: 1px;
  border-radius: 10px;
  padding: 15px;
  color: white;
  font-weight: 600;
}
